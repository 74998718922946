import { Container, Grid } from '@material-ui/core'
import React from 'react'
import BmCard from '../../components/bm-card'
import Layout from '../../components/layout'
import PageHeader from '../../components/page-header'
import GridRow from '../../components/_grid-row'

//education images
import greyPlaceholder from '../../images/grey-placeholder.png';
import personalImg from '../../images/train/personal.jpg';
import kidsImg from '../../images/train/kids.jpg';
import sportsImg from '../../images/train/sports.jpg';

export default function Training() {
    return (
        <Layout pageName="Specialized Training">
            <PageHeader 
                header="Specialized Training"
                desc="Although we have positioned ourselves in the industry as specialists in class-led training programs, we also understand the importance and efficacy of one on one training in order to help clients reach specific short and long term goals. All of our coaches are certified, and specialized to teach their respective skill-set in order to help you achieve particular goals that require one on one attention in our playground. Whether you are a fitness-enthusiast, a competitive athlete, or looking for weight-loss and body re-engineering, we have what you need in order to succeed. "
            />
            
            <Container maxWidth="xl">
                <GridRow>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={personalImg} 
                                cardTitle={{title: 'Personal Training', titleLink: '/specialized-training/personal-training'}}  
                                cardSubTitle="TRAIN TO MOVE"
                                desc="One on one, or in up to groups of 5, you can choose from any of our available classes, scheduled at your convenience, and at the pace and intensity level you require."  
                                learnMore={{text: 'Learn more', link: '/specialized-training/personal-training'}}
                            />
                        </Grid>
                        
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={kidsImg}
                                cardTitle={{title: 'Kids and Youth', titleLink: '/specialized-training/kids-and-youth'}}  
                                cardSubTitle="TRAIN TO LEARN"
                                desc="We believe that kids and youth need to be taught body orientation, movement awareness and proper exercise and training techniques. We start accepting kids into our on-ramp program at the age of 6."  
                                learnMore={{text: 'Learn more', link: '/specialized-training/kids-and-youth'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={sportsImg}
                                cardTitle={{title: 'Sports Performance Training', titleLink: '/specialized-training/sports-performance-training'}}  
                                cardSubTitle="TRAIN TO COMPETE"
                                desc="For those training to excel and compete, we suggest our SPT program which focuses on movement progression, and sports-specific techniques designed particularly around your training and competition needs."  
                                learnMore={{text: 'Learn more', link: '/specialized-training/sports-performance-training'}}
                            />
                        </Grid>
                        
                    </Grid>
                </GridRow>
            </Container>
        </Layout>
    )   
}
